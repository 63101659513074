const config = {
  apiKey: "AIzaSyCvYbCFVrL5Lc65lTvhal0n4O15g67GBrk",
  authDomain: "rader-resume.firebaseapp.com",
  databaseURL: "https://rader-resume.firebaseio.com",
  projectId: "rader-resume",
  storageBucket: "rader-resume.appspot.com",
  messagingSenderId: "163536571893",
  appId: "1:163536571893:web:20551bfd3be67648d9a4bc",
  measurementId: "G-GMKNF5999J",
}

export default config;